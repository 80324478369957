import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  Avatar,
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  ListItemIcon,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import {
  SubmitButton,
  CancelButton,
  CustomChip,
  CustomBadge,
  useStyles,
  TextFieldCustom,
  FormControlCustom,
  SendResetPWButton,
  CustomSwitch,
} from './style.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import EventEmitter from 'src/utils/EventEmitter';
import { items } from 'src/utils/Menu';
import { Settings } from './ComponentTopBar/Settings';
import { Approvals } from './ComponentTopBar/Approvals';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import directus from '../../services/directus';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';
import useStateRef from "react-usestateref";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import ChatIcon from '@mui/icons-material/Chat';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { loadSessions } from '../../Controller/LeaveRequests.js';
import { AdminPermission, EmployeeLeavePermission, SupervisorPermission } from 'src/utils/Permission';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';
import linq from "linq";
import AddEmployeeTimesheet from '../DashboardLayout/NavBar/AddEmployeeTimesheet';
import ExportFile from '../DashboardLayout/NavBar/ExportFile';
import FormGroup from '@mui/material/FormGroup';
import Skeleton from '@mui/material/Skeleton';

const directusEmployeeData = DirectusEmployee();

const companyName = JSON.parse(localStorage.getItem('company'));
const checkPageAccess= (window.location.pathname === '/'|| window.location.pathname.split('/')[1] === 'approvals') ? true : false;
const getStatusReportFilter = localStorage.getItem('report_filter_open_status') ? localStorage.getItem('report_filter_open_status') === 'true' : true;
const getApprovalSidebarStatus = () => {
return localStorage.getItem('approvals_sidebar_open_status') ? JSON.parse(localStorage.getItem('approvals_sidebar_open_status')) : {data: 1, status: true};
}

const TopBar = ({ className, onMobileNavOpen, user, company, ...rest }) => {
  /* eslint-disable */
  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [firstUrl, setFirstUrl] = React.useState('');
  const [features, setFeatures] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [password, setPassword] = React.useState({
    password: '',
    showPassword: false,
  });
  const [firstName, setFirstName, firstNameRef] = useStateRef('');
  const [lastName, setLastName, lastNameRef] = useStateRef('');
  const [mobileNumber, setMobileNumber, mobileNumberRef] = useStateRef('');
  const [email, setEmail, emailRef] = useStateRef('');
  const [userID, setUserID, userIDRef] = useStateRef('');
  const [employee, setEmployee, employeeRef] = useStateRef('');
  const [emailNotificationStatus, setEmailNotificationStatus, emailNotificationStatusRef] = useStateRef('');
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone, timezoneRef] = useStateRef('');
  const [approvalsSidebarOpenStatus, setApprovalsSidebarOpenStatus, approvalsSidebarOpenStatusRef] = useStateRef(getApprovalSidebarStatus());
  const [timezoneFilterChosen, setTimezoneFilterChosen, timezoneFilterChosenRef] = useStateRef('');
  const [userLoading, setUserLoading] = React.useState(null);
  const [sessions, setSessions] = React.useState(null);
  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [summary, setSummary] = React.useState('');
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const { enqueueSnackbar } = useSnackbar();
  const [openDrawer, setOpenDrawer, openDrawerRef] = useStateRef(window.location.pathname === '/reporting' ? 4 : approvalsSidebarOpenStatusRef.current.data);
  const [timesheetLoading, setTimesheetLoading] = React.useState(null);
  const [countUnreadTimesheetReview, setCountUnreadTimesheetReview, countUnreadTimesheetReviewRef] = useStateRef(0);
  const [reportFilterOpenStatus, setReportFilterOpenStatus, reportFilterOpenStatusRef] = useStateRef(getStatusReportFilter);

  let configuration = JSON.parse(window.localStorage.getItem('configuration'));
  let approvals_date = JSON.parse(window.localStorage.getItem('approvals_date'));
  

  var pathname = window.location.pathname;
  const pattern = /^(\/|\/approvals)$/;


  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search('interax') !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      if (AdminPermission() || EmployeeLeavePermission() || SupervisorPermission() ) {
        callSessions();
        loadTimeSheets();
      }
      // eslint-disable-next-line array-callback-return
      items().map((item) => {
        if (item.href === '/' + window.location.pathname.split('/')[1]) {
          setTitle(item.title);
          setFirstUrl(item.href);
          setFeatures(item.features);
        }
        else if (window.location.pathname.split('/')[1] === 'approvals' && window.location.pathname.split('/')[2] !== undefined) {
          setTitle('/approvals');
          setFirstUrl('/approvals');
          setFeatures({ search: false, ExportFile: false, Messages: true, Filter: true, Dashboard: true, Addtimesheet: false });
        }
      })
      loadTimezone();
    }

    const summaryDate = (eventData) => {
        setStartDate(eventData.startdate);
        setEndDate(eventData.enddate);
  
        if (AdminPermission() || EmployeeLeavePermission() || SupervisorPermission()) {
          loadTimeSheets();
          statusSummaryLoad();
        }
    }

    const listenerSummaryDate = EventEmitter.addListener('summaryDate', summaryDate);

    const ReviewButton = (eventData) => {
      if (eventData.text === 'ApprovalsReview') {
        setTitle('/approvals');
        setFirstUrl('/approvals');
        setFeatures({ search: false, ExportFile: true, Messages: true, Filter: true, Dashboard: true, Addtimesheet: true });
      }
    }
    const listenerReviewButton = EventEmitter.addListener('topBar', ReviewButton);

    const changePage = () => {
      dashboardDrawer(openDrawerRef.current);
    }
    const listenChangePage = EventEmitter.addListener('changePage', changePage);

    return () => {
      listenerReviewButton.remove();
      listenerSummaryDate.remove();
      listenChangePage.remove();
      setInitialized(false);

    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps*/

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (name === 'Email Notifications Enabled') {
      setEmailNotificationStatus(e.target.checked);
    }
    else if (name === 'firstname') {
      setFirstName(value);
    }
    else if (name === 'lastname') {
      setLastName(value);
    }
    else if (name === 'mobile_number') {
      setMobileNumber(value);
    }
    else if (name === 'password') {
      setPassword({ ...password, password: value });
    }
  }

  const loadTimeSheets = async () => {
    setTimesheetLoading(true);
    let count = 0;
    let filterData;

    filterData = {
      'employee.status': { in: ['published', 'deleted'] },
      start_time: { gte: startDateRef.current },
      end_time: { lte: endDateRef.current },
    };

    let TimesheetsResult = [];

    try {
      let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
        fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
        filter: filterData,
        limit: -1,
        show_timesheet_review: true,
        timesheet_review_filter:
        {
          "read_by.employees_id": { in: [directusEmployeeData.id] }
        },
      });

      TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    let timesheetId = [];

    TimesheetsResult.map((data) => {
      timesheetId = [...timesheetId, data.id];
    })


    try {

      var TimesheetReview = await directus.api.post('/custom/mobile/timesheets/batch-data', {
        "timesheet_review": {
          fields: '*,employee.user.first_name,employee.user.last_name,read_by.employees_id.id',
          filter: {
            'timesheet.id': { in: timesheetId }
          },
          sort: "created_on"
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.map((data, index) => {
      let result = 0
      result = linq.from(TimesheetReview.data.timesheet_review)
        .where(x => x.timesheet.id === data.id).count();

      TimesheetsResult[index] = { ...data, totalCountTimesheet: result, select: false };
    })

    if (TimesheetsResult) {
      TimesheetsResult.map((data) => {
        count = count + data.timesheet_review_unread_count;
      })

      setCountUnreadTimesheetReview(count);
    }
    else {
      setCountUnreadTimesheetReview(0);
    }

    setTimesheetLoading(false);
  };

  const loadTimezone = async () => {

    let timezoneResult = [];
    var timezone_data = await directus.getField("directus_users", "timezone");
    const timezone_list = Object.entries(timezone_data.data.options.choices);

    //eslint-disable-next-line array-callback-return
    timezone_list.map((data, index) => {
      timezoneResult[index] = { id: data[0], name: data[1], value: data[0] };
    })
    setTimezone(timezoneResult);
  };

  const handleOpenProfile = async () => {
    setOpenProfile(true);
    let myId = JSON.parse(window.localStorage.getItem('directus_user'));
    getUser(myId.id);
    handleClose();
  };
  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const getUser = async (id) => {
    setUserLoading(true);
    var User = await directus.getUser(id);
    setUserID(User.data.id);
    setFirstName(User.data.first_name);
    setLastName(User.data.last_name);
    setEmail(User.data.email);    
    setEmployee(directusEmployeeData.id);
    setMobileNumber(directusEmployeeData.mobile_number);
    setEmailNotificationStatus(directusEmployeeData.enable_email_notifications);
    setTimezoneFilterChosen({ id: 0, name: User.data.timezone, value: User.data.timezone });
    setUserLoading(false);
  };

  const sendEmail = () => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/users/${emailRef.current}`, requestOptions)
      .then(res => console.log(res));

    setLoading(false);
    handleClose();
  }

  const SaveProfile = async () => {
    setLoading(true);

    if (timezoneFilterChosenRef.current.value || firstNameRef.current !== '' || lastNameRef.current !== '') {
      try {
        await directus.updateUser(userID, {
          first_name: firstNameRef.current,
          last_name: lastNameRef.current,
          timezone: timezoneFilterChosenRef.current.value === null || timezoneFilterChosenRef.current.value === '' ? configuration.default_timezone : timezoneFilterChosenRef.current.value,
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (emailNotificationStatusRef.current !== null || mobileNumberRef.current !== null) {
      try {
        await directus.updateItem('employees', employeeRef.current, { enable_email_notifications: emailNotificationStatusRef.current, mobile_number: mobileNumberRef.current });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (password.password !== null && password.password !== "" && password.password !== 'null') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
        body: JSON.stringify({ password: password.password })
      };

      fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/auth/setpassword/` + userIDRef.current, requestOptions)
        .then(response => response.text())
        .then(result => {
          // console.log(result);
        });
    }

    var result = await directus.getItem('employees', employeeRef.current, { fields: '*.*' });
    if (!result.error) {
      window.localStorage.setItem('directus_employee', JSON.stringify(result.data));
    }

    setLoading(false);
    handleCloseProfile();
  }

  const callSessions = async () => {
    setSessions(await loadSessions('null', AdminPermission() ? 'approved' : 'pending'), 1);
  }

  const statusSummaryLoad = async () => {
    try {
      var ApprovalRecord = await directus.api.get('/custom/approvals/summary', {
        fields: '*',
        filter: {
          start_time: { gte: startDateRef.current?startDateRef.current:approvals_date.start},
          end_time: { lte: endDateRef.current?endDateRef.current:approvals_date.end }
        },
      });
      setSummary(ApprovalRecord.data.summary);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const dashboardDrawer = (data) => {
    // set the initial sidebar to open or not based on user choice for reporting page
    if(window.location.pathname === '/reporting') {
      if (reportFilterOpenStatusRef.current) {
        window.DashboardGlobal = true;
        window.DashboardValue = data;
      } else {
        window.DashboardGlobal = false
      }
    }
    // set the initial sidebar to open or not based on user choice for approvals page
    else if (checkPageAccess) {
      const sidebarStatus = getApprovalSidebarStatus();
      if (sidebarStatus.status && sidebarStatus.data === data) {
        window.DashboardGlobal = true;
        window.DashboardValue = data;
      } else {
        window.DashboardGlobal = false
      }
    }

    // close sidebar
    if (window.DashboardGlobal && window.DashboardValue === data) {
      // set a value in local storage to remember the report filter sidebar is closed
      if(data === 4) {
        localStorage.setItem('report_filter_open_status', false)
        setReportFilterOpenStatus(false);
      }
      // set a value in local storage to remember what data is displayed in the approvals page sidebar and set the sidebar to closed
      else if (data === 1 || data === 2 || data === 3) {
        localStorage.setItem('approvals_sidebar_open_status', JSON.stringify({data: data, status: false}))
        setApprovalsSidebarOpenStatus({data: data, status: false});
      }
      window.DashboardGlobal = false;
      setOpenDrawer(0);
    }
    // open sidebar
    else {
      // set a value in local storage to remember the report filter sidebar is opened
      if(data === 4) {
        localStorage.setItem('report_filter_open_status', true)
        setReportFilterOpenStatus(true);
      }
      // set a value in local storage to remember what data is displayed in the approvals page sidebar and set the sidebar to opened
      else if (data === 1 || data === 2 || data === 3) {
        localStorage.setItem('approvals_sidebar_open_status', JSON.stringify({data: data, status: true}))
        setApprovalsSidebarOpenStatus({data: data, status: true});
      }
      window.DashboardGlobal = true;
      window.DashboardValue = data;
      setOpenDrawer(data);
    }

    EventEmitter.emit('contentWidth', {
      data: window.DashboardGlobal,
      text: data
    })

    EventEmitter.emit('dashboardDrawer', {
      text: data
    })
  }

  const changeMenuLoadDrawer = (data) => {
    if (data === 0) {
      window.DashboardGlobal = false;
      setOpenDrawer(0);
    }
    else {
      window.DashboardGlobal = true;
      window.DashboardValue = data;
      setOpenDrawer(data);
    }

    EventEmitter.emit('contentWidth', {
      data: window.DashboardGlobal,
      text: data
    })

    EventEmitter.emit('dashboardDrawer', {
      text: data
    })
  }

  const TopbarIconAccess = (item) => {
    setTitle(item.title);
    setFirstUrl(item.href);
    setFeatures(item.features);
    if (item.title === 'Approvals') {
      // when switching pages, set the data displayed in the approvals sidebar based on user selection
      changeMenuLoadDrawer(getApprovalSidebarStatus().data);
    }
    else if (item.title === 'Reporting') {
      changeMenuLoadDrawer(4);
      
    }
    else {
      changeMenuLoadDrawer(0);
    }
  }

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest} position="fixed" style={{ backgroundColor: '#ffffff', height: '80px' }}>
      <Toolbar id="toolbar" className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={window.location.hostname.search('digitime') !== -1 ? classes.logo : classes.logo1} />
        </RouterLink>

        <Hidden mdDown>
        {items().map((item, index) => (
          <RouterLink to={item.href} key={index} onClick={() => TopbarIconAccess(item)}>
            <div className={`${classes.topbarTab} ${pathname === item.href || (pathname === "/" && item.href === "/approvals") ? classes.selectedTab : ""}`} style={item.listStyle}>
              <span className={`${classes.menuTitle}`}>
                <span>{item.title}</span>
                <span>
                  {item.href === '/leave_requests' ?
                    <CustomChip id="leave_requests_num"
                      size="small"
                      label={<span>{sessions ? sessions.length : 0}</span>}
                    />
                    : item.href === '/approvals' ?
                      <CustomChip id="approvals_num"
                        size="small"
                        label={<span>{summary ? summary : 0}</span>}
                      />
                      : ''}
                </span>
              </span>
            </div>
          </RouterLink>
        ))}
        </Hidden>
        
        <Box flexGrow={1}>
          {firstUrl === '/settings' ? <Settings /> : firstUrl === '/approvals' && window.location.pathname.split('/')[2] === undefined? <Approvals /> : (firstUrl === '/' && title !== 'Timesheet')? <Approvals /> : ''}
        </Box>

        <>
          {(AdminPermission() || EmployeeLeavePermission() || SupervisorPermission()) ?
            <>
              {pattern.test(pathname) ? features.ExportFile ?
                <ExportFile />
                : '' : ''}

              {pattern.test(pathname) ? features.Messages ?
                <CustomBadge overlap="rectangular" badgeContent={countUnreadTimesheetReviewRef.current} color="error">
                  <IconButton id="chat_tab"
                    className={classes.iconFilter}
                    style={openDrawerRef.current === 3 && approvalsSidebarOpenStatusRef.current.data === 3 && approvalsSidebarOpenStatusRef.current.status ? { backgroundColor: "#5DB6FF", color: "white" } : {}}
                    onClick={() => dashboardDrawer(3)}>
                    <ChatIcon />
                  </IconButton>
                </CustomBadge>
                : '' : ''}

              {pattern.test(pathname) ? features.Filter ?
                <IconButton id="filter_tab"
                  className={classes.iconFilter}
                  style={openDrawerRef.current === 2 && approvalsSidebarOpenStatusRef.current.data === 2 && approvalsSidebarOpenStatusRef.current.status ? { backgroundColor: "#5DB6FF", color: "white" } : {}}
                  onClick={() => dashboardDrawer(2)}>
                  <FilterListRoundedIcon />
                </IconButton>
                : '' : ''}
              {features.FilterReporting ?
                <IconButton id="filter_tab"
                  className={classes.iconFilter}
                  style={openDrawerRef.current === 4 && reportFilterOpenStatusRef.current ? { backgroundColor: "#5DB6FF", color: "white" } : {}}
                  onClick={() => dashboardDrawer(4)}>
                  <FilterListRoundedIcon />
                </IconButton>
                : ''}

              {pattern.test(pathname) ? features.Dashboard ?
                <IconButton id="dashboard_tab"
                  className={classes.iconFilter}
                  style={openDrawerRef.current === 1 && approvalsSidebarOpenStatusRef.current.data === 1 && approvalsSidebarOpenStatusRef.current.status ? { backgroundColor: "#5DB6FF", color: "white" } : {}}
                  onClick={() => dashboardDrawer(1)}
                >
                  <DashboardIcon />
                </IconButton>
                : '' : ''}

              {pattern.test(pathname) ? features.Addtimesheet ?
                <AddEmployeeTimesheet />
                : '' : ''}
            </>
            : ''}

          <Avatar className={classes.avatar} src={user.avatar} onClick={handleMenu} id="avatar_menu" />

          <Menu
            id="menu_topbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            isopen={`${open}`}
            open={open}
            onClose={handleClose}
          >

            <List sx={{ width: '100%', maxWidth: 500 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={user.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ id: "user-job_title" }}
                  primary={user.jobTitle}
                  secondaryTypographyProps={{ component: "div" }}
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                      >
                        <span id="user-company">{company}</span> - <span id="user-role">{user.role === "1" ? 'Supervisor' : user.role === "2" ? "Admin" : user.role === "0" ? 'Employee' : user.role}</span>
                        <br />
                        <h5><i> v 1.1.6 </i></h5>
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />

              <MenuItem onClick={handleOpenProfile} >
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <span >Profile</span>
              </MenuItem>

              <MenuItem id="logout_btn" onClick={() => {
                window.localStorage.clear();
                window.location.reload();
              }}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </List>
          </Menu>

          <Dialog
            open={openProfile}
            isopen={`${openProfile}`}
            onClose={handleCloseProfile}
            aria-labelledby="employee_dialog"
            fullWidth
          >
            {userLoading ?
              companyName === 'mfm' ?
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px', width: '45%' }}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px', width: '50%' }}/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius:'8px', marginRight: '10px' }}/>
                    <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius:'8px' }}/>
                  </Grid>
                </DialogContent>
              :
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>                      
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px' }}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px', width: '45%' }}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px', width: '50%' }}/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius:'8px', marginRight: '10px' }}/>
                    <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius:'8px' }}/>
                  </Grid>
                </DialogContent>
              :
              <>
                <DialogTitle id="form-dialog-title"><Typography id="employee_dialog_title" component="div" variant="h6">
                  <h2>
                    {firstNameRef.current} {lastNameRef.current}'s Profile
                  </h2>
                </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    {configuration.employee_settings ?
                      <>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            size='small'
                            id="firstname_text"
                            label="First Name"
                            variant="outlined"
                            name="firstname"
                            value={firstNameRef.current}
                            fullWidth={true}
                            autoComplete="off"
                            onChange={handleChangeInput}
                          />
                          <br />
                          <br />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            size='small'
                            id="lastname_text"
                            label="Last Name"
                            variant="outlined"
                            name="lastname"
                            value={lastNameRef.current}
                            fullWidth={true}
                            autoComplete="off"
                            onChange={handleChangeInput}
                          />
                          <br />
                          <br />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            size='small'
                            id="outlined-basic"
                            label="Mobile Number"
                            variant="outlined"
                            name="mobile_number"
                            value={mobileNumberRef.current}
                            fullWidth={true}
                            autocomplete="off"
                            onChange={handleChangeInput}
                          />
                          <br />
                          <br />
                        </Grid>
                      </>
                      : ''
                    }
                    <Grid item xs={12}>
                      {timezoneRef.current && timezoneFilterChosenRef.current ?
                        <>
                          <Autocomplete
                            size='small'
                            id="combo-box-timezone"
                            options={timezoneRef.current}
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) => option.value === value.value}
                            fullWidth={true}
                            renderInput={(params) => <TextFieldCustom {...params} label="Select Timezone" variant="outlined" />}
                            onChange={(event, newValue) => {
                              newValue === null ?
                                setTimezoneFilterChosen({ id: 0, name: '', value: '' })
                                :
                                setTimezoneFilterChosen({ id: newValue.id, name: newValue.name, value: newValue.value })
                            }}
                            value={timezoneFilterChosenRef.current}
                          />
                          <br />
                        </>
                        : ''
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlCustom size='small' fullWidth={true} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={password.showPassword ? 'text' : 'password'}
                          label="Password"
                          name="password"
                          onChange={handleChangeInput}
                          autocomplete="off"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControlCustom>
                      <SendResetPWButton style={{ marginTop: '10px' }} onClick={() => sendEmail()} color="primary">
                        Send Reset Password Email
                      </SendResetPWButton>
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ display: 'flex', marginTop: '10px', marginLeft: '3px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <CustomSwitch name="Email Notifications Enabled" checked={emailNotificationStatusRef.current} onChange={handleChangeInput} />
                            }
                            label="Email Notifications Enabled"
                          />
                        </FormGroup>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {!loading ?
                    <>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleCloseProfile}
                      >
                        Cancel
                      </CancelButton>

                      <SubmitButton
                        id="save_btn"
                        size="small"
                        variant="contained"
                        onClick={() => SaveProfile()}
                      >
                        Save
                      </SubmitButton>
                    </>
                    : <CircularProgress className={classes.circular} />
                  }
                </DialogActions>
              </>
            }
          </Dialog>
        </>


        <Hidden lgUp>
          <IconButton id="mobile_menu" color="primary" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
